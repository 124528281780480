import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/Logo/LogoImage.png';
import whiteLogo from 'assets/images/Logo/WhiteLogoImage.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();
    const logoPath = theme.palette.mode === 'dark' ? whiteLogo : logo;

    return <img src={logoPath} width={50} height={40} alt="innovative logo" />;
};

export default Logo;

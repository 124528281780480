// ************************************************************************************************************
// ********************************************* FOR SECURED CLOUD SERVER *********************************************
// ************************************************************************************************************

// FOR CLOUD SERVICE
export const servicePort = '9072';

// v1 deployment on 206
export const serviceIp = 'xtremeanalytix-app.com';
export const baseUrl = `https://${serviceIp}:${servicePort}/dataanalytics`;

// v2 deployment on 74
// export const serviceIp = '5.189.150.186';
// export const baseUrl = `http://${serviceIp}:${servicePort}/dataanalytics`;

// CLIENT SERVICE URLS CONFIGURATION
export const postClient = `${baseUrl}/v1/user/create-client`;
export const getClientsUrl = `${baseUrl}/v1/user/get-all-clients`;

// PRODUCT SERVICE URLS CONFIGURATION
export const postProduct = `${baseUrl}/v1/user/create-product`;
export const getProductUrl = `${baseUrl}/v1/user/get-all-products`;
// DATASET SERVICE URLS CONFIGURATION
export const postDataset = `${baseUrl}/v1/user/create-dataset`;
export const getDatasetUrl = `${baseUrl}/v1/user/get-all-datasets`;

// PRODUCT-CLIENT SERVICE URLS CONFIGURATION
export const postTenants = `${baseUrl}/v1/user/create-product-client`;
export const getTenantsUrl = `${baseUrl}/v1/user/get-all-product-clients`;

// PRODUCT-CLIENT-DATASET SERVICE URLS CONFIGURATION
export const postTenantsDataset = `${baseUrl}/v1/user/create-product-client-dataset`;
export const getTenantDatasetUrl = `${baseUrl}/v1/user/get-all-product-client-dataset`;

// PRODUCT-CLIENT-ADMIN SERVICE URLS CONFIGURATION
export const postTenantAdmin = `${baseUrl}/v1/user/create-tenant-admin`;
export const getTenantAdminUrl = `${baseUrl}/v1/user/get-all-tenant-admins`;

// ROLES SERVICE URLS CONFIGURATION
export const postRoles = `${baseUrl}/v1/user/create-role-tenant`;
export const getRolesUrl = `${baseUrl}/v1/user/get-all-roles`;
export const getRolesPermissionUrl = `${baseUrl}/v1/permissions/get-all-resources-perms`;

// USER ROLES SERVICE URLS CONFIGURATION
export const postUserRoles = `${baseUrl}/v1/user/create-user-role-dataset`;
export const getUserRolesUrl = `${baseUrl}/v1/user/get-user-role-dataset`;

// CATALOG SERVICE URLS CONFIGURATION
export const catalogMainUrl = `${baseUrl}/v1/catalog`;
export const catalogList = `${catalogMainUrl}/get-all-catalogs?prodclidsid=`;
export const individualCat = `${catalogMainUrl}/get-catlog`;
export const postCatalog = `${catalogMainUrl}`;
export const postDatasource = `${catalogMainUrl}/create-datasource`;
export function getDataSourcesByCatalogsId(catalogsid) {
    return `${catalogMainUrl}/get-datasource-by-catalogsid?catalogsid=${catalogsid}`;
}

// METADATA SERVICE URLS CONFIGURATION
export const metadataMainUrl = `${baseUrl}/v1/metadata`;
export const metadataList = `${metadataMainUrl}/get-all-metadata/`;

// COLUMN DATA DISCOVERY SERVICE URLS CONFIGURATION
export const columnDataDisMainUrl = `${baseUrl}/v1/columndatadiscovery`;
export const columnDataDisList = `${columnDataDisMainUrl}/get-all-columndatadis/`;
export const columnDataDisObjs = `${columnDataDisMainUrl}/get-columndatadis-objs/`;
export const columnDataDisVisualData = `${columnDataDisMainUrl}/get-visual-data/`;
export const columnDataDisSemiChartVisualData = `${columnDataDisMainUrl}/get-semi-donut/`;

export const columnDataDisPostBiVariantAnalysis = `${columnDataDisMainUrl}/post/bivariant-data`;
export function columnDataDisGetBiVariantAnalysisByprodclidsid(prodclidsid) {
    return `${columnDataDisMainUrl}/get/bivariant-data-by-productclientdatasetsid?prodclidsid=${prodclidsid}`;
}
export function columnDataDisGetBiVariantAnalysisByDsTaId(prodclidsid, taid) {
    return `${columnDataDisMainUrl}/get/bivariant-data-by-productclientdatasetsid-tableid?prodclidsid=${prodclidsid}&taid=${taid}`;
}
export function columnDataDisGetBiVariantAnalysisByUnqCon(ct, prodclidsid, taid, faid, seattids) {
    return `${columnDataDisMainUrl}/get/bivariant-data?ChartType=${ct}&prodclidsid=${prodclidsid}&filterAttId=${faid}&selectedAttIds=${seattids}&taid=${taid}`;
}

// FILE SERVICE URLS CONFIGURATION
export const filesMainUrl = `${baseUrl}/v1/fileupload`;
export const loadFileDataUrl = `${filesMainUrl}/get-json-data?location=`;
export const uploadFile = `${filesMainUrl}/upload`;

// COMPARATIVE ANALYSIS SERVICE URLS CONFIGURATION
export const comparativeAnalysisMainUrl = `${baseUrl}/v1/comparative-analysis`;
export const comparativeAnalysisChiSqrRecs = `${comparativeAnalysisMainUrl}/get-comparative-analysis-list`;

// ML MODELS SERVICE URLS CONFIGURATION
export const mlModelsMainUrl = `${baseUrl}/v1/ml-model`;
export const mlModelsByDSandTABId = `${mlModelsMainUrl}/get-ml-models/`;
export const mlModelTypesList = `${mlModelsMainUrl}/get-all-model-types`;
export const mlModelMetadataList = `${mlModelsMainUrl}/get-ml-models-list/`;
export const mlModelRunsPost = `${mlModelsMainUrl}/model-runs?userroleproductclientdatasetsid=`;
export const mlModelRunsByStatusAndStepCD = `${mlModelsMainUrl}/get-MLModelRuns/`;
export const quickTestAttribs = `${mlModelsMainUrl}/get-published-featured-attribs?userroledatsetid=`;
export const quickTestPost = `${mlModelsMainUrl}/get-published-featured-attribs`;
export const postMlModel = `${mlModelsMainUrl}`;
export const getPublishedMlModel = `${mlModelsMainUrl}/publish-ml-model`;
export function getMlModelCummulativeDataByModelId(modelId) {
    return `${mlModelsMainUrl}/get-MlModelCummulativeData/${modelId}`;
}
// Function to create the Api call for fetching published Ml Model (updated)...
export function getPublishedMlModels(modelId, productclientdatasetsid, tableId) {
    return `${getPublishedMlModel}/${productclientdatasetsid}/${tableId}/${modelId}`;
}

// DASHBOARD SERVICE URLS CONFIGURATION
export const dashboardPost = `${baseUrl}/v1/Dashboard`;
export const dashboardVisualList = `${dashboardPost}/get-all-dashboards?prodclidsid=`;
export const mainDashboardData = `${dashboardPost}/get-main-dashboard-data`;
export const loadMainDashboardData = `${dashboardPost}/load-main-dashboard-data?prodclidsid=`;
export const applicationModeConfiguration = `${dashboardPost}/app-mode-configuration?modeFlag=`;
export const applicationModeStatus = `${dashboardPost}/get-app-mode`;
export const cumulativeData = `${dashboardPost}/get-cumulative-data`;
export function getApplicationModeConfiguration(modeFlag, userroleproductclientdatasetsid) {
    return `${applicationModeConfiguration}productclientdatasetsid=${userroleproductclientdatasetsid}&modeFlag=${modeFlag}`;
}

// PREDICTION SUMMARY SERVICE URLS CONFIGURATION
export const predSumMainUrl = `${baseUrl}/v1/prediction-summary`;
export const predictionSumList = `${predSumMainUrl}/get-prediction-summary-recs`;
export const predictionSumCummalativeList = `${predSumMainUrl}/get-prediction-summary-commulative-recs`;

// USER LOGIN SERVICE URLS CONFIGURATION
export const userSerMainUrl = `${baseUrl}/v1/user`;
export const getUser = `${userSerMainUrl}/get-user?password=`;
// export const getUserRoleDatasets = `${userSerMainUrl}/get-user-role-dataset`;
export const getClubUserRoleAndClientDatasets = `${userSerMainUrl}/get-bulk-user-role-dataset-client-dataset?username=`;
export const getUserDetails = `${userSerMainUrl}/get-user-details`; // Used to retrive user specific datasets

// JOB SERVICE URLS CONFIGURATION
// export const getJobs = `${baseUrl}/v1/jobs/get-job-status?userroleproductclientdatasetsid=`;

// CUSTOM DASHBOARD SCREEN
export const createCustomDashboardScreen = `${baseUrl}/v1/custom_dashboard`;
export const createCustomDashboardComponentLayout = `${baseUrl}/v1/custom_dashboard/component_layout`;
export const createCustomDashboardComponentData = `${baseUrl}/v1/custom_dashboard/component_data`;
// export const getCustomDashboard = `${baseUrl}/v1/custom_dashboard/get-dashboard-screens?productclientdatasetsid=`;
export function getScreenLayoutByprodclidsidAndStatus(screenId, status) {
    return `${baseUrl}/v1/custom_dashboard/get-dashboard-screens-layout-by-status?screenId=${screenId}&status=${status}`;
}
export const createCustomDashboardContentStyling = `${baseUrl}/v1/custom_dashboard/component_layout_content_styling`;
export function getScreenByScreenId(screenId) {
    return `${baseUrl}/v1/custom_dashboard/get-dashboard-screen-by-screenid?screenId=${screenId}`;
}

// CUSTOM FORM
export const postCustomForm = `${baseUrl}/v1/custom_form`;

export function getCustomFormsByFormId(prodclidsid) {
    return `${baseUrl}/v1/custom_form//get-custom-form-structure/${prodclidsid}`;
}
export function getCustomFormsByprodclidsid(prodclidsid) {
    return `${baseUrl}/v1/custom_form/get-custom-forms-by-productclientdatasetsid?productclientdatasetsid=${prodclidsid}`;
}
export function getCustomFormsByprodclidsidandTableIid(prodclidsid, taid) {
    return `${baseUrl}/v1/custom_form/get-custom-forms?productclientdatasetsid=${prodclidsid}&tableid=${taid}`;
}
export const postCustomFormData = `${baseUrl}/v1/custom_form/form_data`;
export function getCustomFormDataByFormId(formid) {
    return `http://${serviceIp}:${servicePort}/dataanalytics/v1/custom_form/get-custom-form-data?formid=${formid}`;
}
export function dltCustomFormDataByFormId(formid) {
    return `${baseUrl}/v1/custom_form/delete-custom-form?formid=${formid}`;
}

// Permission Controller
export function getResourcePermissions(prodcldsid, rleName) {
    return `${baseUrl}/v1/permissions/get-tnt-resource-permissions?productClientDatasetsId=${prodcldsid}&roleName=${rleName}`;
}
export function getTenantResourcePermissions(productClDatasetId, roleName) {
    return `${baseUrl}/v1/permissions/get-tenant-resource-permissions?productClientDatasetsId=${productClDatasetId}&roleName=${roleName}`;
}

// Set Api services
export const setRoleResoursePermission = `${baseUrl}/v1/permissions/set-resource-permission`;
export const delRoleResourcePermission = `${baseUrl}/v1/permissions/del-resource-permission`;
// DASH CHARTS URLS
export const dashChartUrl = 'https://5.189.150.186:9050/';
// export const dashChartUrl = 'https://127.0.0.1:8050/';
// export const dashChartUrl = 'https://5.189.150.186:8050/';
// export const dashChartUrl = 'https://5.189.150.186:8050/';

// CHAT ENGINE URLS
export const chatEngineurl = 'https://127.0.0.1:8000/chat/chat-message';

// CHART TYPE B WIDTH & HEIGHT
export const chartBWidth = 200;
export const chartBHeight = 200;

// *********************************************************************************************************************
// ********************************************* REGISTRATION SERVICE URLS *********************************************
// *********************************************************************************************************************

export const AdminBaseUrl = `https://${serviceIp}:${servicePort}`;
export const superAdminLoginUrl = `${AdminBaseUrl}/registration/v1/authentication/superadmin`;
export const refreshTokenUrl = `${AdminBaseUrl}/registration/v1/authentication/refresh?refresh_token=`;
export const logoutUrl = `${AdminBaseUrl}/registration/v1/authentication/logout?refresh_token=`;
export const loginUrl = `${AdminBaseUrl}/registration/v1/authentication/authenticate`;

// ************************************************************************************************************
// ********************************************* WEB SOCKETS URLS *********************************************
// ************************************************************************************************************

export const DashboardListner = '/topic/message';

export function truncateText(text, len) {
    let txt = '';
    if (text) {
        txt = text.length > len ? `${text.slice(0, len)}...` : text;
    }
    return txt;
}

export const GetAccessToken = () => ({ Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}` });
export const GetJWT = () => window.localStorage.getItem('serviceToken');
export const GetRefreshToken = () => ({ Authorization: `Bearer ${window.localStorage.getItem('serviceRefreshToken')}` });
export const GetRawRefreshToken = () => window.localStorage.getItem('serviceRefreshToken');
export const GetActiveTheme = () => JSON.parse(window.localStorage.getItem('berry-config')).presetColor;

import axios from 'axios';
import { useSelector } from 'react-redux';
import { baseUrl, refreshTokenUrl } from './default';

const api = axios.create({
    baseURL: baseUrl
});

// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('serviceToken');
        // const token = useSelector((state) => state.auth.authToken);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        if (error.response.status === 401 && !originalRequest.retry) {
            originalRequest.retry = true;

            try {
                const refreshToken = localStorage.getItem('serviceRefreshToken');
                // const refreshToken = useSelector((state) => state.auth.refreshToken);
                const response = await axios.post(`${refreshTokenUrl}${refreshToken}`);
                // const { access_token } = response.data.result;
                const accessToken = response.data.result.access_token;
                console.log(accessToken);

                localStorage.setItem('serviceToken', accessToken);
                // Dispatch action to update Redux store
                //  dispatch(setAuthToken(token));

                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                return axios(originalRequest);
            } catch (error) {
                // Handle refresh token error or redirect to login
            }
        }

        return Promise.reject(error);
    }
);

export default api;

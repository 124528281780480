import FilterIcon from '@mui/icons-material/FilterList';
import PrintIcon from '@mui/icons-material/Receipt';
import DownloadIcon from '@mui/icons-material/GetApp';
import ViewColumnIcon from '@mui/icons-material/DynamicFeed';
import { Typography, useTheme, Box } from '@mui/material';

const dataTableIcons = {
    icons: {
        FilterIcon: () => {
            const theme = useTheme();
            console.log(theme, 'themes');
            const isDarkMode = theme.palette.mode === 'dark';
            console.log(theme, 'themePalette');
            return (
                <Box
                    className="icon-container"
                    sx={{
                        background: isDarkMode ? '#414041 !important' : '#FAF9FC',
                        color: 'grey'
                    }}
                >
                    <FilterIcon style={{ color: 'grey' }} />
                    <div style={{ borderRight: '1px solid #ccc', marginRight: '8px', height: '20px' }} />

                    <Typography
                        sx={{
                            pr: 2,
                            pt: 0.5,
                            fontSize: '12px',
                            fontWeight: 'bold !important',
                            color: theme.palette.secondary.dark,

                            '&:hover': {
                                fontSize: '13px'
                            }
                        }}
                    >
                        {' '}
                        Filters{' '}
                    </Typography>
                </Box>
            );
        },
        DownloadIcon: () => {
            const theme = useTheme();
            const isDarkMode = theme.palette.mode === 'dark';
            return (
                <Box
                    className="icon-container"
                    sx={{
                        background: isDarkMode ? '#414041 !important' : '#FAF9FC'
                    }}
                >
                    <DownloadIcon style={{ color: 'grey' }} />
                    <div style={{ borderRight: '1px solid #ccc', marginRight: '8px', height: '20px' }} />

                    <Typography
                        sx={{
                            pr: 2,
                            fontSize: '12px',
                            fontWeight: 'bold !important',
                            color: theme.palette.secondary.dark,
                            pt: 0.5,

                            '&:hover': {
                                fontSize: '13px'
                            }
                        }}
                    >
                        {' '}
                        Download{' '}
                    </Typography>
                </Box>
            );
        },
        PrintIcon: () => {
            const theme = useTheme();
            const isDarkMode = theme.palette.mode === 'dark';
            return (
                <Box
                    className="icon-container"
                    sx={{
                        background: isDarkMode ? '#414041 !important' : '#FAF9FC'
                    }}
                >
                    <PrintIcon style={{ color: 'grey' }} />
                    <div style={{ borderRight: '1px solid #ccc', marginRight: '8px', height: '20px' }} />

                    <Typography
                        sx={{
                            pr: 2,
                            pt: 0.5,

                            fontSize: '12px',
                            fontWeight: 'bold !important',
                            color: theme.palette.secondary.dark,

                            '&:hover': {
                                fontSize: '13px'
                            }
                        }}
                    >
                        {' '}
                        Prints{' '}
                    </Typography>
                </Box>
            );
        },
        ViewColumnIcon: () => {
            const theme = useTheme();
            const isDarkMode = theme.palette.mode === 'dark';
            return (
                <Box
                    className="icon-container"
                    sx={{
                        background: isDarkMode ? '#414041 !important' : '#FAF9FC'
                    }}
                >
                    <ViewColumnIcon style={{ color: 'grey' }} />
                    <div style={{ borderRight: '1px solid #ccc', marginRight: '8px', height: '20px' }} />

                    <Typography
                        sx={{
                            pr: 2,
                            pt: 0.5,

                            fontSize: '12px',
                            fontWeight: 'bold !important',
                            color: theme.palette.secondary.dark,

                            '&:hover': {
                                fontSize: '13px'
                            }
                        }}
                    >
                        {' '}
                        Columns{' '}
                    </Typography>
                </Box>
            );
        }
    }
};

export default dataTableIcons;

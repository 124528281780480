import { getTenantsUrl } from 'views/api-configuration/default';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';
import api from 'views/api-configuration/api';

const initialState = {
    tenants: null
};

const slice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {
        getTenants(state, action) {
            state.tenants = action.payload;
        }
    }
});

export function getTenants() {
    return async () => {
        try {
            // const response = await axios.get(`${getTenantsUrl}`, { headers: GetAccessToken() });
            const response = await api.get(`${getTenantsUrl}`);
            console.log('tenants-response: ', response);
            dispatch(slice.actions.getTenants(response.data.result));
        } catch (error) {
            console.log('==> TENANTS API FETCH FAILED: ', error);
        }
    };
}

export default slice.reducer;

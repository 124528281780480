import { getClientsUrl } from 'views/api-configuration/default';
import { createSlice } from '@reduxjs/toolkit';
import api from '../../views/api-configuration/api';
import { dispatch } from '../index';

const initialState = {
    clients: null
};

const slice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        getClients(state, action) {
            state.clients = action.payload;
        }
    }
});

export function getClients() {
    return async () => {
        try {
            // const response = await axios.get(`${getClientsUrl}`, { headers: GetAccessToken() });
            const response = await api.get(`${getClientsUrl}`);
            console.log('client-response: ', response);
            dispatch(slice.actions.getClients(response.data.result));
        } catch (error) {
            console.log('==> CLIENT API FETCH FAILED: ', error);
        }
    };
}

export default slice.reducer;

import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useTheme, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const CustomSearchInput = ({ searchText, onSearch }) => {
    const [searchValue, setSearchValue] = useState(searchText || '');
    const [selected, setSelected] = useState(false);
    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        onSearch(value);
    };
    const { drawerOpen } = useSelector((state) => state.menu);
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let seachBoxWidth = drawerOpen ? 850 : 990;

    if (isSmallScreen) {
        seachBoxWidth = drawerOpen ? 200 : 250;
    } else if (isMediumScreen) {
        seachBoxWidth = drawerOpen ? 300 : 350;
    }
    const isMagnified125Percent = window.devicePixelRatio === 1.25;

    if (isMagnified125Percent) {
        seachBoxWidth /= 1.25;
    }
    const containerStyles = {
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        margin: '0px',
        marginLeft: '0px',
        width: seachBoxWidth,
        borderRadius: '8px',
        cursor: 'pointer',

        backgroundColor: isDarkMode ? '#414041' : '#FAF9FC'

        // backgroundColor: isDarkMode ? '#414041 !important' : '#FAF9FC'
    };
    const onClicks = () => {
        setSelected(!selected);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onClicks();
        }
    };

    return (
        <div style={containerStyles} role="button" tabIndex={0} onClick={handleInputChange} onKeyDown={handleKeyDown}>
            <SearchIcon style={{ marginRight: '8px', color: 'grey' }} />
            <div style={{ borderRight: '1px solid #ccc', marginRight: '8px', height: '20px' }} />
            <TextField
                type="text"
                value={searchValue}
                onChange={handleInputChange}
                placeholder="Search..."
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true }}
            />
        </div>
    );
};

CustomSearchInput.propTypes = {
    searchText: PropTypes.string.isRequired,
    onSearch: PropTypes.func.isRequired
};

export default CustomSearchInput;

import { getTenantAdminUrl } from 'views/api-configuration/default';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import api from 'views/api-configuration/api';

const initialState = {
    tenantadmin: null
};

const slice = createSlice({
    name: 'tenantadmin',
    initialState,
    reducers: {
        getTenantAdmin(state, action) {
            state.tenantadmin = action.payload;
        }
    }
});

export function getTenantAdmin() {
    return async () => {
        try {
            // const response = await axios.get(`${getTenantAdminUrl}`, { headers: GetAccessToken() });
            const response = await api.get(`${getTenantAdminUrl}`);
            console.log('tenant admin-response: ', response);
            dispatch(slice.actions.getTenantAdmin(response.data.result));
        } catch (error) {
            console.log('==> TENANT ADMIN API FETCH FAILED: ', error);
        }
    };
}

export default slice.reducer;

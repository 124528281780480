import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';
import Login from 'views/new-app/auth-pages/authentication/authentication3/Login';
import GuestGuard from 'utils/route-guard/GuestGuard';

// login routing
const Login3 = Loadable(lazy(() => import('views/new-app/auth-pages/authentication/authentication3/Login3')));

// ==============================|| AUTH ROUTING ||============================== //

// ==============================|| for auth guard || =========================== //

// const withAuthGuard = (component) => {
//     const isAuthenticated = !localStorage.getItem('serviceToken');
//     console.log(isAuthenticated);

//     return isAuthenticated ? component : <Navigate to="/client" replace />;
// };

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            // element: withAuthGuard(<Login />)

            // element: <Login3 />
            element: <Login />
        }
    ]
};

export default LoginRoutes;

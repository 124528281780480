import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { fetchUserData, selectIsAuthenticated, selectLoading } from 'store/slices/authSlice';
import authHeader from 'utils/autherizeToken';
// project imports
// import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    // const { isLoggedIn } = useAuth();
    const dispatch = useDispatch();
    const isAuthenticate = useSelector(selectIsAuthenticated);
    const loading = useSelector(selectLoading);
    console.log(isAuthenticate);
    console.log(loading);

    useEffect(() => {
        // Dispatch the async action when the component mounts
        dispatch(fetchUserData());
    }, [dispatch]);

    return isAuthenticate || loading ? children : <Navigate to="/" />;
    // return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;

import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    pointerEvents: open ? 'auto' : 'auto',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.paper,

    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(180 - 20),
            width: `calc(100% - 100px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - 100px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - 100px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const [setScrollTop] = React.useState(0);
    console.log(theme, 'themeeee');
    const handleScroll = (event) => {
        setScrollTop(event.currentTarget.scrollTop);
    };

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    // const { scroll } = useSelector((state) => state.globe);
    // const { container } = useConfig();

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar
                sx={{
                    p: '0px',
                    pl: '-12px',
                    m: 0,
                    mt: 0,
                    width: '100%',
                    left: 0
                }}
            >
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex', padding: '0px', margin: '0px' }} onScroll={handleScroll}>
            <CssBaseline />
            {/* header */}
            <AppBar
                // enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#f8f8fa',
                    paddingLeft: '0px', // Reduced left padding
                    m: '0px',
                    left: 0,
                    width: drawerOpen ? 235 : '3%',
                    // zIndex: 100,
                    // bgcolor: theme.palette.background.default,
                    transition: drawerOpen ? theme.transitions.create('width') : theme.transitions.create('width')
                }}
            >
                {header}
            </AppBar>
            {/* drawer */}

            <Sidebar />

            <Main theme={theme} open={drawerOpen} sx={{ mt: 0, minHeight: '100vh', p: 0, mr: 0 }}>
                <div style={{ marginTop: '0px' }}>
                    <Outlet />
                </div>
            </Main>
        </Box>
    );
};

export default MainLayout;

import { GetAccessToken, getDatasetUrl } from 'views/api-configuration/default';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';
import api from '../../views/api-configuration/api';

const initialState = {
    datasets: null
};

const slice = createSlice({
    name: 'dataset',
    initialState,
    reducers: {
        getDatasets(state, action) {
            state.datasets = action.payload;
        }
    }
});

export function getDatasets() {
    return async () => {
        try {
            // const response = await axios.get(`${getDatasetUrl}`, { headers: GetAccessToken() });
            const response = await api.get(`${getDatasetUrl}`);

            console.log('dataset-response: ', response);
            dispatch(slice.actions.getDatasets(response.data.result));
            console.log('dataset-response: ', response.data.result);
        } catch (error) {
            console.log('==> DATASET API FETCH FAILED: ', error);
        }
    };
}

export default slice.reducer;

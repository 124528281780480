import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { refreshTokenUrl } from 'views/api-configuration/default';

const refreshToken = localStorage.getItem('serviceRefreshToken');
export const fetchUserData = createAsyncThunk('auth/fetchUserData', async () => {
    const response = await axios.post(`${refreshTokenUrl}${refreshToken}`);
    console.log(response);
});

const initialState = {
    isAuthenticate: false,
    loading: false
};
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state) => {
            state.loading = true;
        },
        logout: (state) => {
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state, action) => {
                state.isAuthenticate = true;
                console.log('pending');
                state.userData = action.payload;
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.isAuthenticate = true;
                console.log('full');
                state.userData = action.payload;
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.isAuthenticate = false;
                console.log('fail');
                state.error = action.error.message;
            });
    }
});

export const { login, logout } = authSlice.actions;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticate;
export const selectLoading = (state) => state.auth.loading;
export default authSlice.reducer;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { getRoleAddPermission } from 'store/slices/roleaddpermission';
import { dispatch } from 'store';
import { useSelector } from 'react-redux';
import {
    GetAccessToken,
    delRoleResourcePermission,
    getTenantResourcePermissions,
    setRoleResoursePermission
} from 'views/api-configuration/default';
import axios from 'axios';
import MainCard from 'ui-component/cards/MainCard';
import dataTableIcons from '../DataTable/DataTableToolbar';
import Loader from 'ui-component/Loader';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import getMuiTheme from '../DataTable/Theme';
import { gridSpacing } from 'store/constant';
import { Grid, Typography, Alert } from '@mui/material';
import CustomSearchInput from '../DataTable/SearchBox';
import Checkbox from '@mui/material/Checkbox';

const AddPermissionForm = () => {
    const { roleNamee, productClientDatasetId } = useParams();
    const { roleAddpermissions } = useSelector((state) => state.roleaddpermission);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    const [loading, setLoading] = useState(true);
    const [showSelectedSuccessMessage, setShowSelectedSuccessMessage] = useState(false);
    const [showUnselectedSuccessMessage, setShowUnselectedSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [updatedProcessedData, setUpdatedProcessedData] = useState([]);
    const theme = useTheme();
    function processData(permissions) {
        const unique = new Set();

        permissions.forEach((item) => {
            const nameParts = item.permissionResourceName.split('_');
            const resourceName = nameParts.slice(0, -1).join(' ');

            if (!unique.has(resourceName)) {
                unique.add(resourceName);
            }
        });

        const arr = [];
        unique.forEach((resourceName) => {
            arr.push({
                resourceName,
                create: resourceName,
                read: resourceName,
                update: resourceName,
                delete: resourceName,
                sidebarview: resourceName
            });
        });

        return arr;
    }

    async function fetchSelectedCheckboxes(productClientDatasetId, roleNamee, showLoading = true) {
        try {
            if (showLoading) {
                setLoading(true);
            }

            const response = await axios.get(getTenantResourcePermissions(productClientDatasetId, roleNamee), {
                headers: {
                    'Content-Type': 'application/json',
                    ...GetAccessToken()
                }
            });

            if (response.status !== 200) {
                console.error('Failed to fetch checkbox state from server:', response.status);
                return;
            }

            const checkboxStateData = response.data.result;
            const updatedCheckboxes = {};

            checkboxStateData.forEach((item) => {
                const nameParts = item.permissionResourceName.split('_');
                const resourceName = nameParts.slice(0, -1).join(' ');
                const action = nameParts[nameParts.length - 1];
                const columnName = action.toLowerCase();

                if (!updatedCheckboxes[resourceName]) {
                    updatedCheckboxes[resourceName] = {
                        create: false,
                        read: false,
                        update: false,
                        delete: false,
                        sidebarview: false
                    };
                }

                updatedCheckboxes[resourceName][columnName] = true;
            });

            setSelectedCheckboxes(updatedCheckboxes);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data from the server:', error);
        }
    }

    useEffect(() => {
        fetchSelectedCheckboxes(productClientDatasetId, roleNamee);
        dispatch(getRoleAddPermission());
    }, [productClientDatasetId, roleNamee]);

    useEffect(() => {
        if (roleAddpermissions && roleAddpermissions.length > 0) {
            const processedData = processData(roleAddpermissions);
            setUpdatedProcessedData(processedData);
        } else {
            setUpdatedProcessedData([]);
        }
    }, [roleAddpermissions]);

    const handleCheckboxClick = async (resourceName, columnName) => {
        console.log(resourceName, 'resourceName', columnName, 'columnName');
        const updatedCheckboxes = {
            ...selectedCheckboxes
        };
        if (!updatedCheckboxes[resourceName]) {
            updatedCheckboxes[resourceName] = {
                create: false,
                read: false,
                update: false,
                delete: false,
                sidebarview: false
            };
        }
        updatedCheckboxes[resourceName][columnName] = !updatedCheckboxes[resourceName][columnName];
        console.log('value checkboxes: ', updatedCheckboxes);
        const permissionResourceName = `${resourceName.replace(/\s/g, '_')}_${columnName.toUpperCase()}`;
        console.log(permissionResourceName, 'permissionResourceName');

        const permissionResourceId = roleAddpermissions.find(
            (item) => item.permissionResourceName === permissionResourceName
        )?.permissionResourceId;
        console.log('value permissionResourceId: ', permissionResourceId);

        if (updatedCheckboxes[resourceName][columnName]) {
            // Checkbox is checked, send a POST request to create the object
            const response = await axios.post(
                setRoleResoursePermission,
                {
                    productClientDatasetsId: productClientDatasetId,
                    resourcePermission: {
                        permissionResourceId,
                        permissionResourceName
                    },
                    roleName: roleNamee
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        ...GetAccessToken()
                    }
                }
            );
            console.log('response: ', response);
            if (response.status === 200) {
                setShowSelectedSuccessMessage(true);
                setShowUnselectedSuccessMessage(false);
                setTimeout(() => {
                    setShowSelectedSuccessMessage(false);
                }, 1000);
                fetchSelectedCheckboxes(productClientDatasetId, roleNamee, false);
            } else {
                console.error('Error creating object:', response.status, response.data);
                setErrorMessage(`Error creating object: ${response.status} - ${response.data}`);
            }
        } else {
            const deletePayload = {
                productClientDatasetsId: productClientDatasetId,
                resourcePermission: {
                    permissionResourceId,
                    permissionResourceName
                },
                roleName: roleNamee
            };

            const deleteResponse = await axios.delete(delRoleResourcePermission, {
                headers: {
                    'Content-Type': 'application/json',
                    ...GetAccessToken()
                },
                data: deletePayload
            });

            if (deleteResponse.status === 200) {
                setShowSelectedSuccessMessage(false);
                setShowUnselectedSuccessMessage(true);
                setTimeout(() => {
                    setShowUnselectedSuccessMessage(false);
                }, 1000);
                fetchSelectedCheckboxes(productClientDatasetId, roleNamee, false);
            } else {
                console.error('Error deleting object:', deleteResponse.status, deleteResponse.data);
                setErrorMessage(`Error deleting object: ${deleteResponse.status} - ${deleteResponse.data}`);
            }
        }
    };

    // const handleRowClick = async (rowData) => {
    //     const resourceName = rowData[0];
    //     const promises = [];
    //     ['create', 'read', 'update', 'delete'].forEach((columnName) => {
    //         const permissionResourceName = `${resourceName}_${columnName.toUpperCase()}`;
    //         const permissionResourceId = roleAddpermissions.find(
    //             (item) => item.permissionResourceName === permissionResourceName
    //         )?.permissionResourceId;

    //         if (!selectedCheckboxes[resourceName] || !selectedCheckboxes[resourceName][columnName]) {
    //             promises.push(
    //                 axios.post(
    //                     setRoleResoursePermission,
    //                     {
    //                         productClientDatasetsId: productClientDatasetId,
    //                         resourcePermission: {
    //                             permissionResourceId,
    //                             permissionResourceName
    //                         },
    //                         roleName: roleNamee
    //                     },
    //                     {
    //                         headers: {
    //                             'Content-Type': 'application/json',
    //                             ...GetAccessToken()
    //                         }
    //                     }
    //                 )
    //             );
    //         }
    //     });

    //     try {
    //         const responses = await Promise.all(promises);
    //         let hasAnyFailed = false;
    //         responses.forEach((response) => {
    //             if (response.status !== 200) {
    //                 hasAnyFailed = true;
    //             }
    //         });
    //         if (hasAnyFailed) {
    //             console.error(`Error selected all of ${resourceName}`);
    //             setErrorMessage(`Error selected all of ${resourceName}`);
    //         } else {
    //             setShowSelectedSuccessMessage(true);
    //             setShowUnselectedSuccessMessage(false);
    //             setTimeout(() => {
    //                 setShowSelectedSuccessMessage(false);
    //             }, 1000);
    //             fetchSelectedCheckboxes(productClientDatasetId, roleNamee, false);
    //         }
    //     } catch (e) {
    //         console.error(`Error selected all of ${resourceName}`);
    //         setErrorMessage(`Error selected all of ${resourceName}`);
    //     }
    // };

    const option = {
        selectableRows: 'none',
        selectableRowsVisibleOnly: false,
        pagination: false,
        elevation: 0,
        searchAlwaysOpen: true,
        searchPlaceholder: 'Search keyword',
        // borderRadius: 4,
        //  rowsPerPage: 100,
        // onRowClick: handleRowClick,
        customSearchRender: (searchText, handleSearch) => <CustomSearchInput searchText={searchText} onSearch={handleSearch} />
    };

    const checkboxStyle = {
        transform: 'scale(1.5)',
        marginRight: '8px'
    };

    const columns = [
        {
            name: 'resourceName',
            label: 'Resource Name',
            width: 300,
            options: {
                filter: true,
                sort: true,
                wrap: true
            }
        },
        {
            name: 'create',
            label: 'Create',
            options: {
                customBodyRender: (value) => (
                    <Checkbox
                        sx={{
                            color: theme.palette.secondary.dark,
                            '&.Mui-checked': {
                                color: theme.palette.secondary.dark,
                                boxShadow: 'none !important'
                            }
                        }}
                        style={checkboxStyle}
                        checked={selectedCheckboxes[value]?.create || false}
                        onChange={() => handleCheckboxClick(value, 'create')}
                    />
                )
            }
        },
        {
            name: 'read',
            label: 'Read',
            options: {
                customBodyRender: (value) => (
                    <Checkbox
                        sx={{
                            color: theme.palette.secondary.dark,
                            '&.Mui-checked': {
                                color: theme.palette.secondary.dark,
                                boxShadow: 'none !important'
                            }
                        }}
                        style={checkboxStyle}
                        checked={selectedCheckboxes[value]?.read || false}
                        onChange={() => handleCheckboxClick(value, 'read')}
                    />
                )
            }
        },
        {
            name: 'update',
            label: 'Update',
            options: {
                customBodyRender: (value) => (
                    <Checkbox
                        sx={{
                            color: theme.palette.secondary.dark,
                            '&.Mui-checked': {
                                color: theme.palette.secondary.dark,
                                boxShadow: 'none !important'
                            }
                        }}
                        style={checkboxStyle}
                        checked={selectedCheckboxes[value]?.update || false}
                        onChange={() => handleCheckboxClick(value, 'update')}
                    />
                )
            }
        },
        {
            name: 'delete',
            label: 'Delete',
            options: {
                customBodyRender: (value) => (
                    <Checkbox
                        sx={{
                            color: theme.palette.secondary.dark,
                            '&.Mui-checked': {
                                color: theme.palette.secondary.dark,
                                boxShadow: 'none !important'
                            }
                        }}
                        style={checkboxStyle}
                        checked={selectedCheckboxes[value]?.delete || false}
                        onChange={() => handleCheckboxClick(value, 'delete')}
                    />
                )
            }
        },
        {
            name: 'sidebarview',
            label: 'Sidebar View',
            options: {
                customBodyRender: (value) => (
                    <Checkbox
                        sx={{
                            color: theme.palette.secondary.dark,
                            '&.Mui-checked': {
                                color: theme.palette.secondary.dark,
                                boxShadow: 'none !important'
                            }
                        }}
                        style={checkboxStyle}
                        checked={selectedCheckboxes[value]?.sidebarview || false}
                        onChange={() => handleCheckboxClick(value, 'sidebarview')}
                    />
                )
            }
        }
    ];

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div
                        className="message-container"
                        style={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginBottom: '10px',
                            position: 'fixed',
                            right: '20px',
                            top: '20px',
                            zIndex: 10000
                        }}
                    >
                        {showSelectedSuccessMessage && (
                            <Alert variant="filled" severity="success">
                                Checkbox selected successfully
                            </Alert>
                        )}
                        {showUnselectedSuccessMessage && (
                            <Alert variant="filled" severity="success">
                                Checkbox unselected successfully
                            </Alert>
                        )}
                        {errorMessage && (
                            <Alert variant="filled" severity="error">
                                {errorMessage}
                            </Alert>
                        )}
                    </div>
                    <MainCard
                        title={
                            <Grid container justifyContent="space-between" spacing={gridSpacing}>
                                <Grid item>
                                    <Typography
                                        component="div"
                                        align="center"
                                        sx={{ color: theme.palette.secondary.dark, fontSize: 24, fontWeight: 'bold' }}
                                    >
                                        Roles Permissions
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                        border={false}
                    >
                        <ThemeProvider theme={getMuiTheme(theme)}>
                            <MUIDataTable
                                data={updatedProcessedData}
                                columns={columns}
                                options={option}
                                components={dataTableIcons}
                                // className="custom-perms-datatable"
                            />
                        </ThemeProvider>
                    </MainCard>
                </>
            )}
        </>
    );
};

export default AddPermissionForm;

import {
    IconBug,
    IconChartDonut2,
    IconChartBubble,
    IconFileImport,
    IconDeviceDesktopAnalytics,
    IconChartInfographic,
    IconNote,
    IconWreckingBall,
    IconShape,
    IconMessages
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconChartDonut2,
    IconChartBubble,
    IconFileImport,
    IconDeviceDesktopAnalytics,
    IconChartInfographic,
    IconNote,
    IconWreckingBall,
    IconShape,
    IconMessages
};
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [pages]
    // items: [customDashboard, dashboard, data, ml, forms, customForms, adv, other]
    items: [
        // {
        //     id: 'dashboards',
        //     title: 'Dashboard',
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'cd',
        //             title: 'Dashboard',
        //             type: 'custom-dashboard-items',
        //             icon: icons.IconDeviceDesktopAnalytics,
        //             url: '/show-dashboard'
        //         }
        //     ]
        // },
        {
            id: 'Client',
            title: 'Client',
            type: 'group',
            children: [
                {
                    id: 'Client',
                    title: 'Client',
                    type: 'item',
                    icon: icons.IconChartInfographic,
                    url: '/client'
                }
            ]
        },
        {
            id: 'product',
            title: 'Product',
            type: 'group',
            children: [
                {
                    id: 'product',
                    title: 'Product',
                    type: 'item',
                    icon: icons.IconDeviceDesktopAnalytics,
                    url: '/product'
                }
            ]
        },
        {
            id: 'Dataset',
            title: 'Dataset',
            type: 'group',
            children: [
                {
                    id: 'Dataset',
                    title: 'Dataset',
                    type: 'item',
                    icon: icons.IconChartDonut2,
                    url: '/dataset'
                }
            ]
        },
        {
            id: 'Tenants',
            title: 'Tenants',
            type: 'group',
            children: [
                {
                    id: 'Tenants',
                    title: 'Tenants',
                    type: 'item',
                    icon: icons.IconWreckingBall,
                    url: '/tenants'
                }
            ]
        },
        {
            id: 'Tenants-Dataset',
            title: 'Tenants Dataset',
            type: 'group',
            children: [
                {
                    id: 'Tenants-Dataset',
                    title: 'Tenants Dataset',
                    type: 'item',
                    icon: icons.IconWreckingBall,
                    url: '/tenants-dataset'
                }
            ]
        },
        {
            id: 'Tenant-Admin',
            title: 'Tenant Admin',
            type: 'group',
            children: [
                {
                    id: 'Tenant-Admin',
                    title: 'Tenant Admin',
                    type: 'item',
                    icon: icons.IconWreckingBall,
                    url: '/tenant-admin'
                }
            ]
        },
        {
            id: 'Roles',
            title: 'Roles',
            type: 'group',
            children: [
                {
                    id: 'Roles',
                    title: 'Roles',
                    type: 'item',
                    icon: icons.IconWreckingBall,
                    url: '/roles'
                }
            ]
        },
        {
            id: 'UserRoles',
            title: 'User Roles',
            type: 'group',
            children: [
                {
                    id: 'UserRoles',
                    title: 'User Roles',
                    type: 'item',
                    icon: icons.IconWreckingBall,
                    url: '/user-roles'
                }
            ]
        }
    ]
};

export default menuItems;

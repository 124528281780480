import PropTypes from 'prop-types';
import { memo, useMemo, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import Customization from 'layout/Customization';
// project imports
import MenuList from './MenuList';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { applicationMode } = useSelector((state) => state.dashboard);

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    useEffect(() => {
        console.log(applicationMode, 'applicationMode changed');
        if (applicationMode !== 'STOPPED') {
            dispatch(openDrawer(true));
        } else {
            dispatch(openDrawer(false));
        }
    }, [dispatch, applicationMode]);
    let heightStyle;

    if (!matchUpMd) {
        heightStyle = drawerOpen ? 'calc(90vh - 90px)' : 'calc(90vh - 80px)';
    } else {
        heightStyle = drawerOpen ? 'calc(90vh - 205px)' : 'calc(90vh - 80px)';
    }
    const drawer = useMemo(
        () => (
            <>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: heightStyle,
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        minHeight: '20px',
                        overflowY: 'scroll'
                    }}
                >
                    <MenuList />
                    <Box
                        display="flex"
                        flexDirection={{ xs: drawerOpen ? 'row' : 'column', md: drawerOpen ? 'row' : 'column' }}
                        alignItems="center"
                        sx={{ bottom: drawerOpen ? 0 : null, pt: 3 }}
                        justifyContent={{ xs: 'space-between' }}
                    >
                        <Customization />
                    </Box>
                </PerfectScrollbar>
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd]
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 90 }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant="persistent"
                anchor="left"
                open={drawerOpen || !drawerOpen}
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerOpen ? drawerWidth : 90,
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#f8f8fa',
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: drawerOpen ? '150px' : '200px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {/* {drawerOpen && logo} */}
                {!drawerOpen && drawer}
                {drawerOpen && drawer}
            </Drawer>

            {/* Button or Icon to toggle Customization */}
        </Box>
    );
};

Sidebar.propTypes = {
    window: PropTypes.object
};

export default memo(Sidebar);

import { getUserRolesUrl } from 'views/api-configuration/default';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';
import api from 'views/api-configuration/api';

const initialState = {
    usersroles: null
};
const slice = createSlice({
    name: 'userrole',
    initialState,
    reducers: {
        getUserRoles(state, action) {
            state.usersroles = action.payload;
        }
    }
});
export function getUserRoles() {
    return async () => {
        try {
            // const response = await axios.get(`${getUserRolesUrl}`, { headers: GetAccessToken() });
            const response = await api.get(`${getUserRolesUrl}`);
            console.log('user roles-response: ', response);
            dispatch(slice.actions.getUserRoles(response.data.result));
        } catch (error) {
            console.log('==> API USER ROLES FETCH FAILED: ', error);
        }
    };
}

export default slice.reducer;

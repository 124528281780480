import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';
import AddPermissionForm from 'views/new-app/components/basic/RoleAddPermission';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const Client = Loadable(lazy(() => import('views/new-app/pages/Client')));
const Product = Loadable(lazy(() => import('views/new-app/pages/Product')));
const Dataset = Loadable(lazy(() => import('views/new-app/pages/Dataset')));
const Tenants = Loadable(lazy(() => import('views/new-app/pages/Tenants')));
const TenantAdmin = Loadable(lazy(() => import('views/new-app/pages/TenantAdmin')));
const Roles = Loadable(lazy(() => import('views/new-app/pages/Roles')));
const TenantsDataset = Loadable(lazy(() => import('views/new-app/pages/TenantsDataset')));
const UserRolesAssign = Loadable(lazy(() => import('views/new-app/pages/UserRolesAssign')));

// ==============================|| MAIN ROUTING ||============================== //

// ==============================|| for protected guard || =========================== //

// const withProtectedGuard = (component) => {
//     const isAuthenticated = localStorage.getItem('serviceToken');
//     console.log(isAuthenticated);

//     return isAuthenticated ? component : <Navigate to="/" replace />;
// };

const MainRoutes = {
    path: '/',
    // element: withProtectedGuard(<MainLayout />),
    // element: <MainLayout />,
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/client',
            element: <Client />
        },
        {
            path: '/product',
            element: <Product />
        },
        {
            path: '/dataset',
            element: <Dataset />
        },
        {
            path: '/tenants',
            element: <Tenants />
        },
        {
            path: '/tenants-dataset',
            element: <TenantsDataset />
        },
        {
            path: '/tenant-admin',
            element: <TenantAdmin />
        },
        {
            path: '/roles',
            element: <Roles />
        },
        {
            path: '/add-permissions/:roleNamee/:productClientDatasetId',
            element: <AddPermissionForm />
        },
        {
            path: '/user-roles',
            element: <UserRolesAssign />
        }
    ]
};

export default MainRoutes;

// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import dashboardReducer from './slices/app-dashboard';
// import globeReducer from './slices/app-globe';
import clientReducer from './slices/client';
import productReducer from './slices/product';
import datasetReducer from './slices/dataset';
import tenantReducer from './slices/tenant';
import tenantdatasetReducer from './slices/tenantdataset';
import tenantadminReducer from './slices/tenantadmin';
import roleReducer from './slices/role';
import roleaddpermissionReducer from './slices/roleaddpermission';
import userroletenantReducer from './slices/userrolesassign';
import authReducer from './slices/authSlice';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    dashboard: dashboardReducer,
    client: clientReducer,
    product: productReducer,
    dataset: datasetReducer,
    tenant: tenantReducer,
    tenantdataset: tenantdatasetReducer,
    role: roleReducer,
    tenantadmin: tenantadminReducer,
    roleaddpermission: roleaddpermissionReducer,
    userrole: userroletenantReducer,
    auth: authReducer
});

export default reducer;

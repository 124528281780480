// material-ui
import { Link } from '@mui/material';

// project imports
// import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link>
        <Logo />
    </Link>
);

export default LogoSection;

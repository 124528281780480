import './Login.css';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'store/slices/authSlice';
import { superAdminLoginUrl } from 'views/api-configuration/default';

// create a schema
const validationSchema = yup.object({
    email: yup.string('Enter your email').required('Email is required'),
    password: yup.string('Enter your password').min(8, 'Password should be of minimum 8 characters length').required('Password is required')
});

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            const response = await axios.post(superAdminLoginUrl, values);
            const accessToken = response.data.result.access_token;
            const refreshToken = response.data.result.refresh_token;
            localStorage.setItem('serviceToken', accessToken);
            localStorage.setItem('serviceRefreshToken', refreshToken);
            dispatch(login());
            navigate('/client');
        }
    });

    return (
        <div className="  main-screen">
            <div className=" my-bg">
                <div className="ctns">
                    <p className=" tag">Nice to see you again</p>
                    <h1 className=" main-heading">Welcome Back</h1>
                    {/* <p className=" main-para">
                        Lorem ipsum dolor sit bore necessitatibus quos voluptate sit. Sint repellat vel uiciatis haf uiciatis jeafds
                        uiciatis sj uiciatis quae.
                    </p> */}
                </div>
            </div>
            <div className="  second-screen  ">
                <div className="Inner ">
                    <div style={{ marginTop: '18%' }}>
                        <h1 className="main-heading-2">Login Account</h1>
                    </div>
                    {/* <p className=" main-para-2 ">
                        Lorem ipsum dolor consectetur adipisicing elit. Ratione omnis maxime voluptas officia sim eos rem, molestiae
                        illequatur iusto similique quisquam.
                    </p> */}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="inp">
                            <TextField
                                sx={{ width: '100%' }}
                                name="email"
                                label="Email"
                                // type="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                // autoComplete="current-password"
                            />
                            <br />
                            <br />

                            <TextField
                                className="text-field"
                                sx={{ width: '100%' }}
                                name="password"
                                label="Password"
                                type="password"
                                // autoComplete="current-password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </div>
                        <div className="down-field">
                            <div className="checkBox">
                                <input type="checkbox" /> check me out
                            </div>
                            <div>
                                <p className=" inp3 ">Alerady have an accoumt?</p>
                            </div>
                        </div>
                        <div style={{ marginTop: '20px' }} className="btn ">
                            <button type="submit" className="login-btn ">
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;

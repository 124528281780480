import { useEffect } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, Typography } from '@mui/material';
// project imports
import LogoSection from '../LogoSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { applicationMode } = useSelector((state) => state.dashboard);
    // const { userInstance } = useSelector((state) => state.userLogin);

    const firstLetter = 'U';

    // const { scroll } = useSelector((state) => state.globe);
    useEffect(() => {
        console.log(applicationMode, 'applicationMode changed');
        if (applicationMode !== 'STOPPED') {
            dispatch(openDrawer(true));
        } else {
            dispatch(openDrawer(false));
        }
    }, [applicationMode, dispatch]);

    return (
        <>
            {/* Logo & toggler button */}
            {drawerOpen && (
                <>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        component="span"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#f8f8fa',
                            display: { xs: 'none', md: 'block', padding: '0px', mt: 2 }
                        }}
                        width="250px"
                    >
                        {' '}
                        <Grid container spacing={14}>
                            <Grid item xs={12} sm={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingLeft: '60px',
                                        height: '100%',
                                        marginTop: '8px'
                                    }}
                                >
                                    <LogoSection />
                                </Box>
                            </Grid>
                            {applicationMode !== 'STOPPED' && (
                                <Grid item xs={12} sm={6}>
                                    <Box
                                        sx={{
                                            width: 60,
                                            display: 'flex',
                                            height: '45px',
                                            [theme.breakpoints.down('md')]: {
                                                width: 60
                                            },
                                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#f8f8fa',
                                            marginTop: '10px'
                                        }}
                                    >
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                ...theme.typography.commonAvatar,
                                                ...theme.typography.mediumAvatar,
                                                border: '1px solid',
                                                borderColor:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                                                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#f8f8fa',
                                                color:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.secondary.dark
                                                        : theme.palette.secondary.dark,
                                                '&:hover': {
                                                    background:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.secondary.main
                                                            : theme.palette.secondary.dark,
                                                    color:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.secondary.light
                                                            : theme.palette.secondary.light
                                                },
                                                transition: 'all .2s ease-in-out'
                                            }}
                                            onClick={() => dispatch(openDrawer(!drawerOpen))}
                                            color="inherit"
                                            style={{ width: '30px', height: '30px' }}
                                        >
                                            <IconMenu2 stroke={1.5} size="1rem" />
                                        </Avatar>
                                    </Box>
                                </Grid>
                            )}{' '}
                        </Grid>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-start">
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: '20px',
                                        paddingLeft: '43px',
                                        mr: '8px',
                                        mb: '2px',
                                        height: '100%'
                                    }}
                                >
                                    <Avatar
                                        sx={{
                                            m: '0 auto',
                                            width: 40,
                                            height: 40,
                                            p: 2,
                                            pl: 2,
                                            border: '1px solid',
                                            borderColor: theme.palette.primary.main,
                                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.primary.main : '#f8f8fa'
                                        }}
                                    >
                                        {firstLetter}
                                    </Avatar>
                                </Box>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        m: '0 auto',
                                        paddingLeft: '60px',
                                        alignContent: 'center',
                                        p: 2,
                                        marginLeft: '20px',
                                        // bgcolor: 'grey',
                                        color: 'grey'
                                    }}
                                >
                                    Admin
                                </Typography>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        m: '0 auto',
                                        padding: '4px',
                                        paddingLeft: '40px',
                                        alignContent: 'center',
                                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#f8f8fa',
                                        color: 'grey'
                                    }}
                                />
                            </Grid>
                        </Box>
                    </Box>
                </>
            )}{' '}
            {!drawerOpen && (
                <>
                    <Box
                        component="span"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#f8f8fa',
                            display: { xs: 'none', md: 'block', p: '0px' },
                            flexGrow: 1,
                            // padding: '4px',
                            // paddingRight: '0px',
                            // paddingBottom: '4px',
                            // paddingTop: '4px',
                            paddingLeft: '-13px',
                            width: '90px'
                        }}
                    >
                        <Grid container spacing={15}>
                            <Grid item xs={12} sm={6}>
                                <Box
                                    sx={{
                                        width: 15,
                                        display: 'flex',
                                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#f8f8fa',
                                        [theme.breakpoints.down('md')]: {
                                            width: 15
                                        },
                                        paddingLeft: '5px',
                                        height: '40px',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.mediumAvatar,
                                            overflow: 'hidden',
                                            transition: 'all .2s ease-in-out',
                                            background:
                                                theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                                            color:
                                                theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                            '&:hover': {
                                                background:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.secondary.main
                                                        : theme.palette.secondary.dark,
                                                color:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.secondary.light
                                                        : theme.palette.secondary.light
                                            }
                                        }}
                                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                                        color="inherit"
                                        style={{ width: '32px', height: '30px' }}
                                    >
                                        <IconMenu2 stroke={1.5} size="1rem" />
                                    </Avatar>
                                </Box>

                                <Box
                                    sx={{
                                        width: 15,
                                        display: 'flex',
                                        alignItems: 'center',
                                        //   paddingLeft: '2px',
                                        height: '100%',
                                        mb: '4px',
                                        marginRight: '8px'
                                    }}
                                >
                                    <LogoSection />
                                </Box>
                            </Grid>{' '}
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    pl: 0,
                                    mr: '16px',
                                    mb: '4px',
                                    height: '100%',
                                    mt: '18px'
                                }}
                            >
                                <Avatar
                                    sx={{
                                        width: 30,
                                        height: 30,
                                        p: 3,
                                        border: '1px solid',
                                        borderColor: theme.palette.primary.main,
                                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.primary.main : '#f8f8fa'
                                    }}
                                >
                                    {firstLetter}
                                </Avatar>
                            </Box>
                        </Grid>
                        <Box>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        m: '0 auto',
                                        mb: 4,
                                        pt: 2,
                                        p: 0,
                                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#f8f8fa',
                                        color: 'grey',
                                        mr: '16px',
                                        mt: '2px'
                                    }}
                                >
                                    Admin{' '}
                                </Typography>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        m: '0 auto',
                                        mb: 2,
                                        p: 0,
                                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#f8f8fa',
                                        color: 'grey'
                                    }}
                                />
                            </Grid>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
};

export default Header;

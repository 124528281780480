import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

// project imports
// import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData, selectIsAuthenticated } from 'store/slices/authSlice';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    // const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticate = useSelector(selectIsAuthenticated);
    console.log(isAuthenticate);

    // useEffect(() => {
    //     if (isAuthenticate) {
    //         navigate(DASHBOARD_PATH, { replace: true });
    //         navisgate('da-main', { replace: true });
    //     }
    // }, [isAuthenticate, navigate]);

    return !isAuthenticate ? children : <Navigate to="/client" />;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;

import { createTheme } from '@mui/material/styles';

const GetMuiTheme = (theme) => {
    const muiTheme = createTheme({
        components: {
            MUIDataTableHeadCell: {
                styleOverrides: {
                    data: {
                        fontWeight: 1000
                    },
                    sortActive: { color: `${theme?.palette.secondary.dark} !important`, fontWeight: 1000 },
                    // responsiveStacked: {
                    //     color: 'black !important', // boxShadow: '5px 4px 8px rgba(0.3, 0.3, 0.1, 0.09)'
                    //     boxShadow: '5px 4px 8px rgba(0.3, 0.3, 0.1, 0.09)'
                    // },
                    fixedHeader: { marginBottom: '1px', borderBottom: '1px solid #ccc' }
                }
            },

            MUIDataTableFilter: {
                styleOverrides: {
                    resetLink: {}
                }
            },

            MUIDataTablePagination: {
                styleOverrides: {
                    tableCellContainer: {
                        padding: '0px'
                    }
                }
            },
            MUIDataTableToolbar: {
                styleOverrides: {
                    iconActive: {}
                }
            },

            MUIDataTableFooter: {
                styleOverrides: {
                    iconActive: {}
                }
            },

            MuiTableSortLabel: {
                styleOverrides: {
                    icon: {
                        color:
                            theme.palette.mode === 'dark'
                                ? `${theme.palette.secondary.dark} !important`
                                : `${theme.palette.secondary.dark} !important`,
                        fontWeight: 1000
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    head: {
                        borderBottom: '1px solid #ccc'
                        // marginBottom: '5px'
                    },

                    root: {
                        '&:hover': {
                            //        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#616161',
                            zIndex: 900
                        }
                    }
                }
            },
            MUIDataTable: {
                styleOverrides: {
                    responsiveBase: {
                        border: '1px solid #ccc',
                        borderTopLeftRadius: '4px  !important',
                        borderTopRightRadius: '4px  !important'
                    },

                    root: {
                        padding: '0px',
                        margin: '0px'
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: `${theme.palette.background.paper} !important`,
                        position: 'sticky',
                        top: 0,
                        width: 20
                    },
                    footer: {
                        backgroundColor: `${theme.palette.background.paper} !important`,
                        color: `grey !important`
                    },
                    body: {
                        border: '0px solid #ccc',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        fontFamily: 'sans-serif'
                        //  color: theme.palette.mode === 'dark' ? `${theme.palette.secondary.dark}` : `${theme.palette.secondary.dark}`,
                        // '&:first-child': {
                        //    color: '#999',
                        //     width: 20
                        // }
                    },
                    root: {
                        border: '0px solid #ccc',
                        fontWeight: '1000',
                        //  ...theme.typography.body2,
                        margin: '12px'
                    }
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    selectIcon: { ...theme.typography.body2 },
                    selectLabel: { ...theme.typography.body2 },
                    displayedRows: { ...theme.typography.body2 },
                    select: { ...theme.typography.body2 },
                    actions: { color: `grey !important` },
                    root: {
                        borderTop: '0px solid #ccc',
                        borderLeft: '1px solid #ccc !important',
                        borderRight: '1px solid #ccc !important',
                        borderBottom: '1px solid #ccc !important',
                        borderBottomLeftRadius: '8px !important',
                        borderBottomRightRadius: '8px !important',
                        width: '100%',
                        //       ...theme.typography.caption,
                        padding: '0px',
                        boxShadow: '0 0px 0px rgba(0, 0, 0, 0) !important'
                    }
                }
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        ...theme.typography.body2
                    }
                }
            },

            MuiToolbar: {
                styleOverrides: {
                    root: {
                        //    color: theme.palette.mode === 'dark' ? '#414041 !important' : '#FAF9FC',

                        margin: '0px !important',
                        padding: '0px !important'
                    }
                }
            },

            MuiIconButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            ...theme.typography.body2,
                            //   boxShadow: 'none !important',
                            backgroundColor: 'transparent !important',
                            fontSize: '2.8rem !important'
                        }
                    }
                }
            },

            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        border: '0px solid #ccc'
                        // borderRadius: '4px',
                        // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f8f8fa'
                    }
                }
            }
        },

        // MuiTypography: {
        //     styleOverrides: {
        //         root: {
        //             ...theme.typography.body2
        //         }
        //     }
        // },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: `${theme?.palette.secondary.dark} !important`,
                    '&.Mui-checked': {
                        color: `${theme?.palette.secondary.dark} !important`,
                        boxShadow: 'none !important'
                    }
                }
            }
        },
        palette: {
            ...theme.palette
        },
        typography: {
            ...theme.typography
        }
    });

    return muiTheme;
};
export default GetMuiTheme;

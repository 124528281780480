import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Drawer, Grid, Box, Avatar, Tooltip } from '@mui/material';
import { IconSettings, IconLogout } from '@tabler/icons';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import BorderRadius from './BorderRadius';
import Layout from './Layout';
import PresetColor from './PresetColor';
// import FontFamily from './FontFamily';
import InputFilled from './InputFilled';
import BoxContainer from './BoxContainer';
import { gridSpacing } from 'store/constant';
import { useNavigate } from 'react-router-dom';

// Redux Imports
// import { useDispatch } from 'store';
// import { useSelector } from 'react-redux';
// import { configureApplicationMode } from 'store/slices/app-dashboard';
// import { getSelectedDatasetSuccess } from 'store/slices/user-login';
// import Avatar from '@mui/material/Avatar';

// ==============================|| LIVE CUSTOMIZATION ||============================== //

const Customization = () => {
    const theme = useTheme();
    // const dispatch = useDispatch();
    // const { applicationMode } = useSelector((state) => state.dashboard);
    // const { selectedDataset } = useSelector((state) => state.userLogin);

    // function onApplicationModeChange(val) {
    //     if (selectedDataset !== null && selectedDataset !== undefined)
    //         dispatch(configureApplicationMode(val, selectedDataset.userroledatasetid));
    // }

    // function onDatasetChange(datasetid) {
    //     console.log('CALLED');
    //     dispatch(getSelectedDatasetSuccess(datasetid));
    // }

    // drawer on/off
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
        // toggle();
    };
    const navigate = useNavigate();
    const handleLogout = () => {
        navigate('/');
    };
    return (
        <>
            <Box
                sx={{
                    pl: 1,
                    //   ml: 1,

                    [theme.breakpoints.down('md')]: {
                        //    ml: 1
                        pl: 1
                    }
                }}
            >
                <Tooltip title="settings">
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            border: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                            transition: 'all .2s ease-in-out',
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                borderColor: theme.palette.primary.main,
                                background: theme.palette.primary.main,
                                color: theme.palette.primary.light
                            }
                        }}
                        onClick={handleToggle}
                        color="inherit"
                        style={{ width: '30px', height: '30px' }}
                    >
                        <IconSettings stroke={1.5} size="1rem" />
                    </Avatar>
                </Tooltip>
            </Box>
            <Box
                sx={{
                    pr: 1,
                    [theme.breakpoints.down('md')]: {
                        pr: 1
                    }
                }}
            >
                <Tooltip title="logout">
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            border: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                            transition: 'all .2s ease-in-out',
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                borderColor: theme.palette.primary.main,
                                background: theme.palette.primary.main,
                                color: theme.palette.primary.light
                            }
                            //  ml: 1
                        }}
                        onClick={handleLogout}
                        color="inherit"
                        style={{ width: '30px', height: '30px' }}
                    >
                        <IconLogout stroke={1.5} size="1rem" />
                    </Avatar>
                </Tooltip>
            </Box>
            <Drawer
                anchor="right"
                onClose={handleToggle}
                open={open}
                PaperProps={{
                    sx: {
                        width: 280
                    }
                }}
            >
                {open && (
                    <PerfectScrollbar component="div">
                        <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                            <Grid item xs={12}>
                                <Layout />
                            </Grid>
                            <Grid item xs={12}>
                                <PresetColor />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <FontFamily />
                            </Grid> */}
                            <Grid item xs={12}>
                                <BorderRadius />
                            </Grid>
                            <Grid item xs={12}>
                                <InputFilled />
                            </Grid>
                            <Grid item xs={12}>
                                <BoxContainer />
                            </Grid>
                        </Grid>
                    </PerfectScrollbar>
                )}
            </Drawer>
        </>
    );
};

export default Customization;
